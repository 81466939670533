import { useQuery } from '@tanstack/react-query'
import { get } from '../utils'
import { QUERY_KEYS, SECTION_KEYS } from '../../config/constants'
import moment from 'moment'

async function getManagerData() {
  return await get('api/vacancy/selection')
}

export function useVacancyRequestManager() {
  return useQuery([QUERY_KEYS.VACANCY_REQUEST], getManagerData, {})
}

async function getInboxAssignData({ queryKey }) {
  const [_key, { searchName }] = queryKey
  let searchNameFilter = searchName ? '&searchName=' + encodeURI(JSON.stringify({ v: searchName, o: 'CONTAINS' })) : ''
  return await get('api/vacancy/selection?sort[]=["createdAt","desc"]&state=["active","empty"]' + searchNameFilter)
}

export function useVacancyRequestToAssignCandidates(searchName = null, options = {}) {
  return useQuery([QUERY_KEYS.VACANCY_REQUEST, { searchName }], getInboxAssignData, options)
}

async function getManagerDataPending({ queryKey }) {
  let [_key, { range, sort, startDate, endDate }] = queryKey
  range = range ? encodeURI(JSON.stringify(range)) : ''
  sort = encodeURI(JSON.stringify(sort))
  return await get(
    `api/vacancy/selection?sort[]=${sort}&state=pending&range=${range}&createdAt[]={"o": ">=", "v": "${moment(startDate).format(
      'YYYY-MM-DD 00:00:00'
    )}"}&createdAt[]={"o": "<=", "v": "${moment(endDate).format('YYYY-MM-DD 23:59:59')}"}`
  )
}

export function useVacancyRequestManagerPending(range, sort, startDate, endDate) {
  return useQuery([QUERY_KEYS.VACANCY_REQUEST, { range, sort, startDate, endDate }], getManagerDataPending)
}

async function getManagerDataReviewed({ queryKey }) {
  let [_key, { range, sort, startDate, endDate }] = queryKey
  range = range ? encodeURI(JSON.stringify(range)) : ''
  sort = encodeURI(JSON.stringify(sort))
  return await get(
    `api/vacancy/selection?sort[]=${sort}&state=["empty", "active", "closed", "rejected", "cancelled", "inactive"]&range=${range}&createdAt[]={"o": ">=", "v": "${moment(
      startDate
    ).format('YYYY-MM-DD 00:00:00')}"}&createdAt[]={"o": "<=", "v": "${moment(endDate).format('YYYY-MM-DD 23:59:59')}"}`
  )
}

export function useVacancyRequestManagerReviewed(range, sort, startDate, endDate) {
  return useQuery([QUERY_KEYS.VACANCY_REQUEST, { range, sort, startDate, endDate }], getManagerDataReviewed)
}

export function vacancyRequestFilterParams(context) {
  const search = getContextFilters(context)
  if (Object.keys(search).length === 0) {
    return {
      filter: {},
      querystring: ''
    }
  }

  const queryParams = new URLSearchParams()
  for (const key in search) {
    if (search[key] !== undefined && search[key] !== null && search[key] !== '') {
      switch (key) {
        case 'createdAt':
          queryParams.append(`${key}[]`, JSON.stringify({ o: '>=', v: moment(search[key], 'DD/MM/YYYY', true).format('YYYY-MM-DD') + ' 00:00:00' }))
          break
        case 'startDateAt':
          queryParams.append(`${key}[]`, JSON.stringify({ o: '>=', v: moment(search[key], 'DD/MM/YYYY', true).format('YYYY-MM-DD') + ' 00:00:00' }))
          break
        case 'endAt':
          queryParams.append(`${key}[]`, JSON.stringify({ o: '<=', v: moment(search[key], 'DD/MM/YYYY', true).format('YYYY-MM-DD') + ' 23:59:59' }))
          break
        case 'endDateAt':
          queryParams.append(`${key}[]`, JSON.stringify({ o: '<=', v: moment(search[key], 'DD/MM/YYYY', true).format('YYYY-MM-DD') + ' 23:59:59' }))
          break
        default:
          if (Array.isArray(search[key]) && search[key][0] && search[key][0].id !== undefined) {
            queryParams.append(key, JSON.stringify(search[key].map(place => place.id)))
          } else {
            queryParams.append(key, search[key])
          }
          break
      }
    }
  }

  const paramsObj = Object.fromEntries(queryParams.entries())
  const cleanedParamsObj = Object.entries(paramsObj).reduce((acc, [key, value]) => {
    if (value !== '' && value !== 'null' && value !== 'undefined') {
      acc[key] = value
    }
    return acc
  }, {})

  const cleanedQueryParams = new URLSearchParams(cleanedParamsObj).toString()

  return {
    filter: cleanedParamsObj,
    querystring: `${cleanedQueryParams}`
  }
}

export function getContextFilters(context) {
  return context[SECTION_KEYS.VACANCY_REQUESTS]?.filters ?? {}
}

export const exportVacancyRequestCsvOnBackground = async (queryFilters, selectionIds) => {
  let url = `/api/vacancy/background-export?filter=${encodeURI(JSON.stringify(queryFilters.filter))}`
  if (Array.isArray(selectionIds) && selectionIds.length > 0) {
    url += `&selectionIds=${encodeURI(JSON.stringify(selectionIds))}`
  }

  return get(url)
}

function preventEmptySortRequest(sort) {
  if (!Array.isArray(sort) || sort.length === 0 || sort.every(item => item === '')) {
    return ['createdAt','desc']
  }
  return sort
}

async function getManagerDataOpen({ queryKey }) {
  let [_key, { range, sort, search }] = queryKey
  range = range ? encodeURI(JSON.stringify(range)) : ''
  sort = encodeURI(preventEmptySortRequest(JSON.stringify(sort)))

  if (Object.keys(search).length !== 0) {
    const queryParams = new URLSearchParams()
    for (const key in search) {
      if (search[key] !== undefined && search[key] !== null && search[key] !== '') {
        switch (key) {
          case 'createdAt':
            queryParams.append(`${key}[]`, JSON.stringify({ o: '>=', v: moment(search[key], 'DD/MM/YYYY', true).format('YYYY-MM-DD') + ' 00:00:00' }))
            break
          case 'startDateAt':
            queryParams.append(`${key}[]`, JSON.stringify({ o: '>=', v: moment(search[key], 'DD/MM/YYYY', true).format('YYYY-MM-DD') + ' 00:00:00' }))
            break
          case 'endAt':
            queryParams.append(`createdAt[]`, JSON.stringify({ o: '<=', v: moment(search[key], 'DD/MM/YYYY', true).format('YYYY-MM-DD') + ' 23:59:59' }))
            break
          case 'endDateAt':
            queryParams.append(`startDateAt[]`, JSON.stringify({ o: '<=', v: moment(search[key], 'DD/MM/YYYY', true).format('YYYY-MM-DD') + ' 23:59:59' }))
            break
          default:
            if (Array.isArray(search[key]) && search[key][0] && search[key][0].id !== undefined) {
              queryParams.append(key, JSON.stringify(search[key].map(place => place.id)))
            } else {
              queryParams.append(key, search[key])
            }
            break
        }
      }
    }
    
    let cleanedQueryParams = new URLSearchParams();
    for (let [key, value] of queryParams.entries()) {
      if (value !== '' && value !== 'null' && value !== 'undefined') {
        cleanedQueryParams.append(key, value);
      }
    }

    if (search.state && search.state.length !== 0) {
      return await get(`api/vacancy/selection?${cleanedQueryParams.toString()}&sort[]=${sort}&range=${range}`)
    } else {
      return await get(`api/vacancy/selection?${cleanedQueryParams.toString()}&sort[]=${sort}&range=${range}&state=["empty", "active", "pending"]`)
    }
  } else {
    return await get(`api/vacancy/selection?sort[]=${sort}&state=["empty", "active", "pending"]&range=${range}`)
  }
}

export function useVacancyRequestManagerOpen(range, sort, search) {
  return useQuery([QUERY_KEYS.VACANCY_REQUEST, { range, sort, search }], getManagerDataOpen)
}

async function getManagerDataClose({ queryKey }) {
  let [_key, { range, sort, search }] = queryKey
  range = range ? encodeURI(JSON.stringify(range)) : ''
  sort = encodeURI(preventEmptySortRequest(JSON.stringify(sort)))

  if (Object.keys(search).length !== 0) {
    const queryParams = new URLSearchParams()
    for (const key in search) {
      if (search[key] !== undefined && search[key] !== null && search[key] !== '') {
        switch (key) {
          case 'createdAt':
            queryParams.append(`${key}[]`, JSON.stringify({ o: '>=', v: moment(search[key], 'DD/MM/YYYY', true).format('YYYY-MM-DD') + ' 00:00:00' }))
            break
          case 'startDateAt':
            queryParams.append(`${key}[]`, JSON.stringify({ o: '>=', v: moment(search[key], 'DD/MM/YYYY', true).format('YYYY-MM-DD') + ' 00:00:00' }))
            break
          case 'endAt':
            queryParams.append(`${key}[]`, JSON.stringify({ o: '<=', v: moment(search[key], 'DD/MM/YYYY', true).format('YYYY-MM-DD') + ' 23:59:59' }))
            break
          case 'endDateAt':
            queryParams.append(`${key}[]`, JSON.stringify({ o: '<=', v: moment(search[key], 'DD/MM/YYYY', true).format('YYYY-MM-DD') + ' 23:59:59' }))
            break
          default:
            if (Array.isArray(search[key]) && search[key][0] && search[key][0].id !== undefined) {
              queryParams.append(key, JSON.stringify(search[key].map(place => place.id)))
            } else {
              queryParams.append(key, search[key])
            }
            break
        }
      }
    }

    const paramsObj = Object.fromEntries(queryParams.entries())
    const cleanedParamsObj = Object.entries(paramsObj).reduce((acc, [key, value]) => {
      if (value !== '' && value !== 'null' && value !== 'undefined') {
        acc[key] = value
      }
      return acc
    }, {})

    const cleanedQueryParams = new URLSearchParams(cleanedParamsObj).toString()

    if (search.state && search.state.length !== 0) {
      return await get(`api/vacancy/selection?${cleanedQueryParams}&sort[]=${sort}&range=${range}`)
    } else {
      return await get(`api/vacancy/selection?${cleanedQueryParams}&sort[]=${sort}&range=${range}&state=["closed", "rejected", "cancelled", "inactive"]`)
    }
  } else {
    return await get(`api/vacancy/selection?sort[]=${sort}&state=["closed", "rejected", "cancelled", "inactive"]&range=${range}`)
  }
}

export function useVacancyRequestManagerClose(range, sort, search) {
  return useQuery([QUERY_KEYS.VACANCY_REQUEST, { range, sort, search }], getManagerDataClose)
}
