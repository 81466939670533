import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid'
import MyProfileKeywordsRpaStyled from './styled'
import { Box } from '@material-ui/core'
import { RegularButton, InputField } from '../../atoms'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { MTText, IconEdit, IconBlockUser, IconActiveUser, CustomAlert } from '../../atoms'
import MaterialTable from 'material-table';
import ModalNewIntegration from '../../molecules/modal-new-integration';
import _ from 'lodash';
import ModalDisableIntegration from '../../molecules/modal-disable-integration';
import { useRpaIntegrations } from '../../../api/queries/useRpaIntegration';
import { INTEGRATIONS_RPA, ROLES } from '../../../config/constants'
import { Alarm, CheckCircle, Error, Pause, VpnKey } from '@material-ui/icons'
import moment from 'moment';
import { AuthUtils } from '../../../redux/auth'

const MyProfileKeywordsRpa = ({ i18n, classes, self_data }) => {

    const isAdminUser = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_talent_acquisition) || AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_ROOT);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDisableOpen, setIsModalDisableOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editingIntegration, setEditingIntegration] = useState(null);
    const [searchTerm, setSearchTerm] = useState({ term: '' });
    const [providerFilter, setProviderFilter] = useState('');
    const [isDisable, setIsDisable] = useState(false);
    const [isEnable, setIsEnable] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({ message: '', severity: '' })

    useEffect(() => {
        if (alert.message) {
            setShowAlert(true);
            const timer = setTimeout(() => {
                setShowAlert(false);
                setAlert({ message: '', severity: '' });
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [alert]);

    const options = [
        { id: INTEGRATIONS_RPA.INFOJOBS_PROVIDER.default, name: i18n.keywords_rpa.sourceInfojobs },
        { id: INTEGRATIONS_RPA.EPRESELEC_PROVIDER.default, name: i18n.keywords_rpa.sourceEpreselec },
        { id: INTEGRATIONS_RPA.COMPUTRABAJO_PROVIDER.default, name: i18n.keywords_rpa.sourceComputrabajo },
        { id: INTEGRATIONS_RPA.PANDAPE_PROVIDER.default, name: i18n.keywords_rpa.sourcePandape },
        { id: INTEGRATIONS_RPA.TURIJOBS_PROVIDER.default, name: i18n.keywords_rpa.sourceTurijobs },
        { id: INTEGRATIONS_RPA.INDEED_PROVIDER.default, name: i18n.keywords_rpa.sourceIndeed },
        { id: INTEGRATIONS_RPA.HELLOWORK_PROVIDER.default, name: i18n.keywords_rpa.sourceHellowork },
        { id: INTEGRATIONS_RPA.BIZNEO_PROVIDER.default, name: i18n.keywords_rpa.sourceBizneo },
    ];

    const optionsPriority = [
        { id: INTEGRATIONS_RPA.INFOJOBS_PROVIDER.priority, name: i18n.keywords_rpa.sourceInfojobsPriority },
        { id: INTEGRATIONS_RPA.EPRESELEC_PROVIDER.priority, name: i18n.keywords_rpa.sourceEpreselecPriority },
        { id: INTEGRATIONS_RPA.COMPUTRABAJO_PROVIDER.priority, name: i18n.keywords_rpa.sourceComputrabajoPriority },
        { id: INTEGRATIONS_RPA.PANDAPE_PROVIDER.priority, name: i18n.keywords_rpa.sourcePandapePriority },
        { id: INTEGRATIONS_RPA.TURIJOBS_PROVIDER.priority, name: i18n.keywords_rpa.sourceTurijobsPriority },
        { id: INTEGRATIONS_RPA.INDEED_PROVIDER.priority, name: i18n.keywords_rpa.sourceIndeed },
        { id: INTEGRATIONS_RPA.HELLOWORK_PROVIDER.priority, name: i18n.keywords_rpa.sourceHellowork },
        { id: INTEGRATIONS_RPA.BIZNEO_PROVIDER.priority, name: i18n.keywords_rpa.sourceBizneo },
    ]

    const { data: rpaData, refetch } = useRpaIntegrations(self_data.client_id);

    const handleDataUpdate = async () => {
        try {
            await refetch();
            setIsModalOpen(false);
            setIsModalDisableOpen(false);

            if (isEnable || isDisable) {
                setAlert({
                    message: isEnable
                        ? i18n.keywords_rpa.integrationDisabledSuccessfully
                        : i18n.keywords_rpa.integrationEnabledSuccessfully,
                    severity: 'success',
                    section: isEnable ? 'inactive' : 'active',
                });

                setIsEnable(false);
                setIsDisable(false);
            }
        } catch (error) {
            console.error('error', error);
        }
    };

    const filterData = (searchTermValue, providerFiltered, isActive) => {
        if (!rpaData || rpaData.length === 0) {
            return [];
        }

        const allOptions = [...options, ...optionsPriority];

        const nameFilteredData = rpaData.filter((rpa) => {
            const isUserActive = rpa.active === isActive;
            if (!isUserActive) {
                return false;
            }

            const userMatch = rpa.user?.toLowerCase().includes(searchTermValue.toLowerCase());
            const candidatureMatch = rpa.position_name.toLowerCase().includes(searchTermValue.toLowerCase());
            const providerMatch = rpa.provider?.toLowerCase().includes(searchTermValue.toLowerCase());

            return userMatch || candidatureMatch || providerMatch;
        });

        const providerFilteredData = providerFiltered
            ? nameFilteredData.filter((user) => {
                const selectedProvider = allOptions.find(option => String(option.id) === String(user.provider));
                const profileName = selectedProvider ? selectedProvider.name : user.provider;

                return profileName.toLowerCase() === providerFiltered.toLowerCase();
            })
            : nameFilteredData;

        return providerFilteredData;
    };

    const activeIntegrations = filterData(searchTerm.term, providerFilter, true);
    const inactiveIntegrations = filterData(searchTerm.term, providerFilter, false);

    const handleProviderFilter = (_, value) => {
        setProviderFilter(value ? value.id : '');
    };

    const handleSearchTerm = _.debounce((value) => {
        setSearchTerm({ term: value });
    }, 250);

    const handleEditIconClick = (row) => {
        if (row.active) {
            setEditingIntegration(row);
            setIsEdit(true);
            setIsModalOpen(true);
        }
    };

    const handleDisableAndEnableIconClick = (row) => {
        const currentStatus = row.active;
        setEditingIntegration(row);
        setIsEdit(false);
        setIsModalDisableOpen(true);
        setIsDisable(!currentStatus);
        setIsEnable(currentStatus);
    };

    const tableOptions = {
        emptyRowsWhenPaging: false,
        loadingType: 'linear',
        cellStyle: { textAlign: 'center' },
        pageSize: 10,
        pageSizeOptions: [10, 25, 50, 100],
        toolbar: false,
        draggable: false,
        selection: false,
    }

    const getIconByIntegrationStatus = (status) => {
        const icons = {
            pending: <Alarm />,
            ok: <CheckCircle style={{color: 'green'}}/>,
            error_login: <VpnKey color="error" />,
            error_generic: <Error color="error"/>,
        };

        return icons[status] || <Alarm />;
    };

    const tableColumns = {
        position: {
            title: i18n.keywords_rpa.position,
            render: function PositionColumn(row) {
                return <MTText value={row.position_name} style={{ fontWeight: '400' }} />;
            },
            field: 'position_name'
        },
        provider: {
            title: i18n.keywords_rpa.source,
            render: function SourceColumn(row) {
                let providerName;

                if (row.provider === INTEGRATIONS_RPA.INFOJOBS_PROVIDER.priority) {
                    providerName = i18n.keywords_rpa.sourceInfojobsPriority;
                }
                else if (row.provider === INTEGRATIONS_RPA.EPRESELEC_PROVIDER.priority) {
                    providerName = i18n.keywords_rpa.sourceEpreselecPriority;
                }
                else {
                    const selectedProvider = options.find(option => String(option.id) === String(row.provider));
                    providerName = selectedProvider ? selectedProvider.name : row.provider;
                }

                return <MTText value={providerName} style={{ fontWeight: '400' }} />;
            },
            field: 'provider'
        },
        user: {
            title: i18n.keywords_rpa.user,
            render: function UserColumn(row) {
                return <MTText value={row.user} style={{ fontWeight: '400' }} />;
            },
            field: 'user'
        },
        keyword: {
            title: i18n.keywords_rpa.keyword,
            render: function KeywordColumn(row) {
                return <MTText value={row.keyword} style={{ fontWeight: '400' }} />;
            },
            field: 'keyword'
        },
        connectionStatus: {
            title: i18n.keywords_rpa.connectionStatus,
            render: function ConnectionStatusColumn(row) {
                const title = row.active ? (i18n.keywords_rpa.connectionStatuses[row.status] || row.status || i18n.keywords_rpa.connectionStatuses.pending) : i18n.keywords_rpa.inactiveIntegration;
                const icon = row.active ? getIconByIntegrationStatus(row.status) : <Pause />;
                return <div style={{ textAlign: 'left', cursor: 'help' }} title={title}>
                    {icon}
                </div>;
            },
            field: 'status',
            hidden: false,
        },
        lastExecution: {
            title: i18n.keywords_rpa.lastExecution,
            render: function ConnectionStatusColumn(row) {
                const date = row.last_execution_date ? moment(row.last_execution_date).format('DD/MM/YYYY HH:mm') : '-';
                return <MTText value={date} style={{ fontWeight: '400' }} />;
            },
            field: 'status',
            hidden: false,
            customSort: (a, b) => {
                return (new Date(a.last_execution_date || null).getTime()) - (new Date(b.last_execution_date || null).getTime());
            }
        },
        editIntegration: {
            title: '',
            hidden: !isAdminUser,
            render: function EditUserColumn(row) {
                if(row.flow_type === 'evaluation_profile') {
                    return <></>
                }

                const isIntegrationActive = row.active;
                const editIconClass = isIntegrationActive ? 'custom-edit' : 'custom-edit disabled-edit';
                return <div className={editIconClass} onClick={() => handleEditIconClick(row)} >
                    <IconEdit />
                </div>
            },
            field: 'editUser',
        },
        disableAndEnableIntegration: {
            title: '',
            hidden: !isAdminUser,
            render: function DisableUserColumn(row) {
                if(row.flow_type === 'evaluation_profile') {
                    return <></>
                }

                const icon = row.active ? <IconBlockUser /> : <IconActiveUser />;
                return (
                    <div className='custom-delete' onClick={() => handleDisableAndEnableIconClick(row)} >
                        {icon}
                    </div>
                );
            },
            field: 'disableAndEnableUser',
        }
    };

    const columnsArray = Object.values(tableColumns);

    columnsArray.forEach(column => {
        if (column.render) {
            column.render.displayName = `${column.field}_render`;
        }
    });

    const handleNewIntegration = () => {
        setIsEdit(false);
        setIsModalOpen(true);
    };

    return (
        <div className={classes.root}>
            <MyProfileKeywordsRpaStyled>
                <div className="containerHeader">
                    <Grid container spacing={4}>
                        <Grid item className="sectionSecond" xs={12} sm={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "flex-start", gridGap: '15px', maxWidth: "700px", }}>
                                <h2>{i18n.keywords_rpa.titleSection}</h2>
                                <h5>{i18n.keywords_rpa.descriptionSection}</h5>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "flex-end", gridGap: '15px', justifyContent: 'flex-end', maxWidth: "400px" }}>
                                {isAdminUser && <Box sx={{ display: 'flex', alignItems: "flex-end", placeSelf: 'flex-end' }}>
                                    <RegularButton className="button-custom" title={i18n.keywords_rpa.newIntegration}
                                                   onClick={handleNewIntegration}
                                    />
                                </Box>}
                                <Box sx={{ display: 'inline-flex', alignItems: "center", }}>
                                    <label>{i18n.keywords_rpa.searchLabel}</label>
                                    <InputField
                                        placeholder={i18n.evaluativeTests.placeholderInputTerm}
                                        onChange={(e) => {
                                            e.persist();
                                            handleSearchTerm(e.target.value);
                                        }}
                                    />
                                </Box>
                                <Box item sx={{ display: 'inline-flex', alignItems: "center" }}>
                                    <label>{i18n.keywords_rpa.filterSource}</label>
                                    <Autocomplete
                                        className='autocomplete-custom'
                                        options={options}
                                        getOptionLabel={(option) => option.name}
                                        onChange={handleProviderFilter}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder={i18n.keywords_rpa.placeholderFilter} variant="outlined" />
                                        )}
                                        style={{ width: 250 }}
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} >
                            <Grid item className="headerTable">
                                <h3>{i18n.keywords_rpa.activeIntegrations}</h3>
                                {showAlert && !isDisable && alert.section === 'inactive' && <CustomAlert severity={alert.severity} message={alert.message} />}

                            </Grid>
                            <MaterialTable
                                data={activeIntegrations}
                                columns={columnsArray}
                                options={tableOptions}
                            />
                        </Grid>

                        {inactiveIntegrations && inactiveIntegrations.length > 0 &&
                            <Grid item xs={12} sm={12}>
                                <Grid item className="headerTable">
                                    <h3>{i18n.keywords_rpa.inactiveIntegrations}</h3>
                                    {showAlert && !isEnable && alert.section === 'active' && <CustomAlert severity={alert.severity} message={alert.message} />}

                                </Grid>
                                <MaterialTable
                                    data={inactiveIntegrations}
                                    columns={columnsArray.map(column => {
                                        if (column.field === 'editUser') {
                                            return {
                                                ...column,
                                            };
                                        }
                                        return column;
                                    })}
                                    options={tableOptions}
                                />
                            </Grid>
                        }

                        <ModalNewIntegration
                            open={isModalOpen}
                            handleClose={() => setIsModalOpen(false)}
                            i18n={i18n}
                            isEdit={isEdit}
                            editingIntegration={editingIntegration}
                            self_data={self_data}
                            onDataUpdate={handleDataUpdate}
                        />

                        <ModalDisableIntegration
                            open={isModalDisableOpen}
                            handleClose={() => setIsModalDisableOpen(false)}
                            i18n={i18n}
                            self_data={self_data}
                            onDataUpdate={handleDataUpdate}
                            integrationData={editingIntegration}
                        />
                    </Grid>
                </div>
            </MyProfileKeywordsRpaStyled>
        </div>
    );
};

export default MyProfileKeywordsRpa;
