import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import {getSectionKeys, MODULES, SECTION_KEYS} from "../config/constants";
import {CustomAlert} from "../components/atoms";

const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
}

export const createStructure = (value, alternativeValues = {}) => {
  let fields = getSectionKeys();
  let result = {};
  fields.map(key => result[key] = value);

  Object.keys(alternativeValues).map(key => {
    result[key] = alternativeValues[key];
  });

  return result;
}

export const AppContextProvider = ({children}) => {
  const [filters, setFilters] = useState(createStructure({}, {
    [SECTION_KEYS.FAVORITES]: {isFavorite: true},
    [SECTION_KEYS.JOB_OFFERS]: { status: true },
    [SECTION_KEYS.VACANCY_REQUESTS]: { showOpenProcesses: true, validationShowPendingProcesses: true, filters: {} },
  }));
  const [sort, setSort] = useState(createStructure(["", ""], {
    [SECTION_KEYS.JOB_OFFERS]: ["created_at", "DESC"],
    [SECTION_KEYS.JOB_OFFER_APPLICATIONS]: ["created_at", "DESC"],
    [SECTION_KEYS.VACANCY_REQUESTS]: ['createdAt', 'DESC'],
  }));
  const [range, setRange] = useState(createStructure([0, 4], {
    [SECTION_KEYS.JOB_OFFERS]: [0, 9],
    [SECTION_KEYS.JOB_OFFER_APPLICATIONS]: [0, 19],
    [SECTION_KEYS.VACANCY_REQUESTS]: [0, 9],
  }));
  const [navigationNextId, setNavigationNextId] = useState(null);
  const [page, setPage] = useState(createStructure(0));
  const [perPage, setPerPage] = useState(createStructure(20));
  const [module, setModule] = useState(MODULES.currentModule());
  const [filterGroup, setFilterGroup] = useState(createStructure({}));
  const [profiles, setProfiles] = useState({});
  const [savedSearchItems, setSavedSearchItems] = useState({});
  const [goBackLocation, setGoBackLocation] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const [guards, setGuards] = useState({
    sections: {},
  });

  const clearTargetFilters = (key) => {
    setFilters({...filters, [key]: {}});
    setPage({...page, [key]: 0});
    setRange({...range, [key]: [0, 19]});
    setSort({...sort, [key]: ['', '']});
    setFilterGroup(-1);
  }

  const setFilterGroupSelected = (sectionKey, id, selectedGroupId) => {
    if (filterGroup[sectionKey][id] && filterGroup[sectionKey][id] === selectedGroupId) return;
    let sectionFilterGroup = JSON.stringify(filterGroup[sectionKey]);

    sectionFilterGroup = JSON.parse(sectionFilterGroup);
    sectionFilterGroup[id] = selectedGroupId;
    setFilterGroup({...filterGroup, [sectionKey]: sectionFilterGroup});
  }

  const clearContextIfNeeded = (currentModule) => {
    if (module !== currentModule) {
      setFilters(createStructure({}));
      setSort(createStructure(["", ""]));
      setRange(createStructure([0, 19]));
      setPage(createStructure(0));
      setPerPage(createStructure(20));
      setFilterGroup(createStructure({}));
      setProfiles({});
      setSavedSearchItems({});
    }
    setModule(currentModule);
  }

  const createAlert = (message, severity, timeout = 3000) => {
    setAlert({message: message, severity: severity, timeout: timeout});
  }

  const isEnabledGuardSection = (sectionKey) => {
    return guards.sections.hasOwnProperty(sectionKey) ? guards.sections[sectionKey] : false
  }

  let value = useMemo(() => {
    return {
        guards, setGuards, isEnabledGuardSection,
        filters, setFilters,
        sort, setSort,
        range, setRange,
        page, setPage,
        perPage, setPerPage,
        module, setModule,
        filterGroup, setFilterGroup,
        profiles, setProfiles,
        savedSearchItems, setSavedSearchItems,
        goBackLocation, setGoBackLocation,
        navigationNextId, setNavigationNextId,
        setFilterGroupSelected,
        clearContextIfNeeded,
        clearTargetFilters,
        createAlert,
    }
  }, [filters, sort, range, page, perPage, filterGroup, savedSearchItems, profiles, goBackLocation, guards, navigationNextId]);

  useEffect(() => {
    if (alert.message) {
      setShowAlert(true);
      const timer = setTimeout(() => {
        setShowAlert(false);
        setAlert({ message: '', severity: '' });
      }, alert.timeout || 3000);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  return <AppContext.Provider value={value}>
    {children}
    {showAlert && <CustomAlert severity={alert.severity} message={alert.message} fixed={true} />}
  </AppContext.Provider>
}
